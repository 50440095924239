import { SpSvg } from '../autogen/SpSvg'
import { CardTypes } from '../constants/cardTypes'
import { IMessageCardType } from './../types/IMessageCardTypes'

export const messageCardTypes: {
    [K in CardTypes]: IMessageCardType
} = {
    [CardTypes.INFO]: {
        mainColor: 'bg-main-blue-50',
        accentColor: 'bg-main-blue-400',
        iconColor: 'fill-main-blue-600',
        icon: SpSvg.BasicInfoCircle,
    },
    [CardTypes.SUCCESS]: {
        mainColor: 'bg-green-50',
        accentColor: 'bg-green-400',
        iconColor: 'fill-green-600',
        icon: SpSvg.BasicCheckCircle,
    },
    [CardTypes.WARNING]: {
        mainColor: 'bg-yellow-50',
        accentColor: 'bg-yellow-400',
        iconColor: 'fill-yellow-600',
        icon: SpSvg.BasicAlertCircle,
    },
    [CardTypes.ERROR]: {
        mainColor: 'bg-red-50',
        accentColor: 'bg-red-400',
        iconColor: 'fill-red-600',
        icon: SpSvg.BasicErrorCircle,
    },
}
